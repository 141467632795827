import React from "react";
import styled from "styled-components";
import SocialIcons from "./SocialIcons";

const Hero = (props) => (
  <HeroContainer className="fade-in-hero__first">
    <HeroTitle>
      <Bold>Michael Chun</Bold>
    </HeroTitle>
    <HeroText>
      Software Engineer <span className="mobile-hide">/</span>{" "}
      <br className="mobile-break" />
      Based in NYC
      <br />
      <br className="mobile-break" />
    </HeroText>
    <HeroSub>
      {" "}
      <p className="hero__sub-header">
        Currently at{" "}
        <a
          href="https://conceal.io/"
          className="underline"
          target="_blank"
          rel="noopener noreferrer"
        >
          Conceal <span className="mobile-arrow">↗</span>
        </a>{" "}
      </p>
    </HeroSub>
    <SocialIcons />
  </HeroContainer>
);

export default Hero;

const HeroContainer = styled.div`
  margin: 0 auto;
  max-width: 700px;
  margin-top: 10%;
  margin-bottom: 64px;
  margin-left: 15%;
  background: white;

  @media (max-width: 400px) {
    margin-left: 8%;
    margin-top: 96px;
    height: 55vh;
  }
`;

const HeroTitle = styled.h1`
  font-size: 26px;
  font-weight: 300;
  margin-bottom: 5px;
  @media (max-width: 400px) {
    font-size: 26px;
  }
`;

const HeroText = styled.p`
  font-weight: 400;
  font-size: 26px;
  line-height: 35px;
  font-weight: 300;
  margin-top: 0px;
  margin-bottom: 24px;
  @media (max-width: 400px) {
    font-size: 26px;
    margin: 0;
    font-weight: 200;
  }
`;

const HeroSub = styled.div`
  @media (max-width: 400px) {
    margin-bottom: 12px;
  }
`;

const Bold = styled.span`
  font-weight: 700;
  @media (max-width: 400px) {
    letter-spacing: -0.5px;
  }
`;
