import React from "react";
import SEO from "../components/seo";
import Hero from "../components/Hero";
import "../components/layout.css";

const IndexPage = () => {
  return (
    <>
      <SEO title="Home" />
      <div className="container-test">
        <Hero />
      </div>
    </>
  );
};

export default IndexPage;
